import React from 'react'
import { graphql } from "gatsby";
import { Container, Row, Col } from 'react-bootstrap'
import Layout from "../../components/Layout";
import SEO from "../../components/SEO";

const ProcurementtoPayment = ({ data }) => {

	const P2P_landing_introduction = data.P2P_landing_introduction.nodes;
	const P2P_landing_experience_P2P_cycle_1 = data.P2P_landing_experience_P2P_cycle_1.nodes;
	const P2P_landing_experience_P2P_cycle_2 = data.P2P_landing_experience_P2P_cycle_2.nodes;
	const P2P_landing_experience_P2P_cycle_3 = data.P2P_landing_experience_P2P_cycle_3.nodes;
	const P2P_landing_whyusing_detail = data.P2P_landing_whyusing_detail.nodes;
	const P2P_landing_whyusing_img = data.P2P_landing_whyusing_img.nodes;

	return (
		<Layout>
            <SEO title="Procurement to Payment" description="Appvity Procurement to Payment." meta={[{ name: `keywords`, content: ["eRequest", "collaboration", "task management", "issue management", "helpdesk system"] }]} pathname="/products" />
			<div className="main-content-padding">
				<div className="app-general-seciton app-P2Planding-introduction" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
					<Container>
						<div className="app-P2Planding-introduction-detail">
							<Row className="app-P2Planding-introduction-row-custom">
								<Col className="app-P2Planding-introduction-col-custom" xs={12} md={12} lg={5}>
									<div className="app-P2Planding-introduction-left">
										{P2P_landing_introduction.map((res) => (
											<div dangerouslySetInnerHTML={{ __html: res.html }} key={res.id}/>
										))}
									</div>
								</Col>
								<Col className="app-P2Planding-introduction-col-right" xs={12} md={12} lg={7}></Col>
							</Row>
						</div>
					</Container>
				</div>
				<div className="app-general-seciton app-experience-P2P-cycle" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
					<Container>
						<Row>
							<Col xs={12} md={12} lg={2}></Col>
							<Col xs={12} md={12} lg={8}>
								<div className="app-title">
									<h2>
										Experience an End-to-End Procurement to Payment Cycle
										<br/>
										integrated within Microsoft Teams
									</h2>
								</div>
							</Col>
							<Col xs={12} md={12} lg={2}></Col>
						</Row>
						<div className="app-section-experience-P2P-cycle-detail">
							<Row className="app-row-top-align-center">
								<Col xs={12} md={12} lg={4} className="app-col-experience-1">
									{P2P_landing_experience_P2P_cycle_1.map((res) => (
										<div className="app-P2P-budgetplaning" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
									))}
								</Col>
								<Col xs={12} md={12} lg={4} className="app-col-experience-2">
									{P2P_landing_experience_P2P_cycle_2.map((res) => (
										<div className="app-P2P-procurementactivities" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
									))}
								</Col>
								<Col xs={12} md={12} lg={4} className="app-col-experience-3">
									{P2P_landing_experience_P2P_cycle_3.map((res) => (
										<div className="app-P2P-paymentexecution" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
									))}
								</Col>
							</Row>
						</div>
					</Container>
					<div className="app-P2Planding-spacing"></div>
				</div>
				<div className="app-general-seciton app-whyusing" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
					<Container>
						<Row>
							<Col xs={12} md={12} lg={2}></Col>
							<Col xs={12} md={12} lg={8}>
								<div className="app-title">
									<h2>Why using our Procurement to Payment (P2P)</h2>
								</div>
							</Col>
							<Col xs={12} md={12} lg={2}></Col>
						</Row>
						<div className="app-section-whyusing-detail">
							<Row className="app-row-margin-0 app-row-top-align-center">
								<Col xs={12} md={12} lg={6} className="app-col-padding-0 app-col-p2p-whyusing-detail">
									{P2P_landing_whyusing_detail.map((res) => (
										<div className="app-P2P-whyusing-detail" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
									))}
								</Col>
								<Col xs={12} md={12} lg={6} className="app-col-padding-0 app-col-p2p-whyusing-img">
								</Col>
							</Row>
						</div>
					</Container>
					<div className="app-P2Planding-spacing"></div>
				</div>
			</div>
		</Layout>
	)
}

export default ProcurementtoPayment


export const query = graphql`
    query ProcurementtoPaymentLandingPage {
		P2P_landing_introduction: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "P2P_landing_introduction" } } }
        ) {
            nodes {
                html
                id
            }
        }
		P2P_landing_experience_P2P_cycle_3: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "P2P_landing_experience_P2P_cycle_3" } } }
        ) {
            nodes {
                html
                id
            }
        }
		P2P_landing_experience_P2P_cycle_1: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "P2P_landing_experience_P2P_cycle_1" } } }
        ) {
            nodes {
                html
                id
            }
        }
		P2P_landing_experience_P2P_cycle_2: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "P2P_landing_experience_P2P_cycle_2" } } }
        ) {
            nodes {
                html
                id
            }
        }
		P2P_landing_whyusing_detail: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "P2P_landing_whyusing_detail" } } }
			sort: { fields: frontmatter___keyNumber, order: ASC }
        ) {
            nodes {
                html
                id
            }
        }
		P2P_landing_whyusing_img: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "P2P_landing_whyusing_img" } } }
			sort: { fields: frontmatter___keyNumber, order: ASC }
        ) {
            nodes {
                html
                id
            }
        }
    }
`;